import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import SlideDialog from '../SlideDialog';
import Geosuggest from 'react-geosuggest';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default React.memo(({ open, title, onClose, onSuggestSelect }) => {
  const classes = useStyles();

  return (
    <SlideDialog
      open={open}
      title={title}
      onClose={onClose}
    >
      <Container maxWidth="sm" className={classes.bg}>
        <Box my={2}>
          <Geosuggest country="th" onSuggestSelect={onSuggestSelect} />
        </Box>
      </Container>
    </SlideDialog>
  );
});
