import axios from 'axios';

export const locationFromGeo = (lat, lng) => {
  return axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.GATSBY_MAP_KEY}&language=th&region=TH`,
    )
    .then(response => {
      return response.data.results;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
