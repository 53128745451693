import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { postAddress } from '../actions/orderInfo';
import { createLoadingSelector } from '../reducers/api';
import { locationFromGeo } from '../apis/geo';
import { debounce } from '../helpers/utility';
import * as Api from '../apis';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';
import LocationIcon from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import SaveIcon from '@material-ui/icons/Save';
import GoogleMap from '../components/GoogleMap';
import Button from '../components/Button';
import PlaceAutocompleteSlideDialog from '../components/PlaceAutocompleteSlideDialog';
import InfoDialog from '../components/InfoDialog';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '100%',
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
  },
  icon: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '44%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  currentLocation: {
    position: 'absolute',
    bottom: 24,
    left: 8,
  },
  textField: {
    position: 'absolute',
    top: '12%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: 6,
    width: '90%',
  },
  save: {
    position: 'absolute',
    bottom: 24,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  locationIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  currentLocationButton: {
    height: '80px',
  },
  snackbarAnchor: {
    top: '140px',
  },
  snackbarContent: {
    justifyContent: 'center',
  },
}));

let mapInit = false;

export default () => {
  const dispatch = useDispatch();
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const session = useSelector(state => state.session);
  const orderInfo = useSelector(state => state.orderInfo);
  const isLoading = useSelector(createLoadingSelector(['address/post']));
  const [openInfo, setOpenInfo] = useState(false);
  const [address, setAddress] = useState(
    orderInfo.address ? orderInfo.address.address : '',
  );
  const [location, setLocation] = useState({
    latitude: orderInfo.address
      ? parseFloat(orderInfo.address.latitude)
      : 13.7633148,
    longitude: orderInfo.address
      ? parseFloat(orderInfo.address.longitude)
      : 100.5334619,
  });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (orderInfo.address) {
      setAddress(orderInfo.address.address);
      setLocation({
        latitude: parseFloat(orderInfo.address.latitude),
        longitude: parseFloat(orderInfo.address.longitude),
      });
    }
  }, [orderInfo.address]);

  useEffect(() => {
    setOpenInfo(true);
  }, []);

  const classes = useStyles();

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => setLocation(position.coords),
        err =>
          alert(
            'You need to enable location for this function to work. Please check your app permission.',
          ),
        { timeout: 60000 },
      );
    } else {
      alert('Sorry, browser does not support geolocation!');
    }
  };

  const handleInfoClose = () => {
    setOpenInfo(false);
  };

  const onSave = () => {
    dispatch(postAddress(address, location.latitude, location.longitude)).then(
      () => {
        // work around get shipping distance because orderInfo.distance not update in this function
        Api.getShippingPrice(location.latitude, location.longitude)
          .then(result => {
            if (result.distance > 20) {
              setShowInfoDialog(true);
            } else {
              window.history.back();
            }
          })
          .catch(() => {
            window.history.back();
          });
      },
    );
  };

  const onLocationChange = location => {
    if (mapInit && !modal) {
      setLocation({
        latitude: location.center.lat,
        longitude: location.center.lng,
      });
      locationFromGeo(location.center.lat, location.center.lng).then(
        results => {
          if (results[0]) {
            setAddress(results[0]['formatted_address']);
          }
        },
      );
    }
    mapInit = true;
  };

  const onSuggestSelect = location => {
    if (!location) return;
    setAddress(location.description);
    setLocation({
      latitude: location.location.lat,
      longitude: location.location.lng,
    });
    setModal(false);
  };

  const closeInfoDialog = () => {
    setShowInfoDialog(false);
    window.history.back();
  };

  return (
    <div className={classes.mapContainer}>
      <InfoDialog
        open={showInfoDialog}
        okText={<span style={{ color: 'red' }}>ปิด</span>}
        onClose={closeInfoDialog}
        onConfirm={closeInfoDialog}
        disableBackdropClick={true}
        content={
          <div style={{ color: 'black' }}>
            อยู่นอกระยะให้บริการ กรุณาติดต่อพนักงาน
          </div>
        }
      />
      <Snackbar
        classes={{
          anchorOriginTopCenter: classes.snackbarAnchor,
        }}
        open={openInfo}
        autoHideDuration={5000}
        onClose={handleInfoClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <SnackbarContent
          classes={{
            root: classes.snackbarContent,
          }}
          message={<Box align='center'>กรุณาเลือกที่อยู่</Box>}
        />
      </Snackbar>
      <PlaceAutocompleteSlideDialog
        open={modal}
        title='Search Place'
        onClose={() => setModal(false)}
        onSuggestSelect={onSuggestSelect}
      />
      {!modal && (
        <GoogleMap
          noMarker
          defaultZoom={14}
          center={location}
          onChange={debounce(onLocationChange, 500)}
        />
      )}
      <div className={classes.textField}>
        <Paper className={classes.paper}>
          <InputBase
            fullWidth
            // disabled
            placeholder='ที่จัดส่ง'
            value={address}
            onClick={() => {
              setModal(true);
              mapInit = false;
            }}
          />
        </Paper>
      </div>
      <div className={classes.icon}>
        <LocationIcon fontSize='large' />
      </div>
      {session.source !== 'android' && (
        <div className={classes.currentLocation}>
          <Button
            variant='extended'
            type='fab'
            color='secondary'
            className={classes.currentLocationButton}
            onClick={getCurrentLocation}
            icon={
              <div className={classes.locationIcon}>
                <MyLocationIcon />
                <span>ตำแหน่ง</span>
                <span>ปัจจุบัน</span>
              </div>
            }
          />
        </div>
      )}
      <div className={classes.save}>
        <Button
          type='fab'
          variant='extended'
          color='secondary'
          onClick={onSave}
          loading={isLoading}
          icon={<SaveIcon />}
        >
          บันทึก
        </Button>
      </div>
    </div>
  );
};
